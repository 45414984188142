import React from "react";
import { Blurhash } from "react-blurhash";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import { Parallax } from "react-parallax";

import SectionTitle from "../components/SectionTitle";
import VideoSection from "./VideoSection";
import bgImage from "../assets/images/gray-crop1-min.jpg";
import { useEffect } from "react/cjs/react.development";

export default function HeroSection(props) {
  return (
    <section id="home" className="page-scroll">
      <VideoSection />
    </section>
  );
  return (
    <>
      <section id="home" className="page-scroll" data-aos="fade-down">
        <Parallax bgImage={bgImage} blur={{ min: -15, max: 15 }}>
          <div className="container-fluid" style={{ height: "100vh" }}>
            <div id="scroll-down" className="intro">
              <div className="intro-content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 text-left content-left">
                      <div style={{ color: "white" }}>
                        <div
                          className="section-title-container"
                          style={{ marginBottom: "2rem" }}
                        >
                          <h2>Энхбаярын</h2>
                          <h1
                            className="text-primary"
                            style={{
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }}
                          >
                            БАТШУГАР
                          </h1>
                        </div>
                        <div>
                          <div id="typer">
                            <h4>Эдийн засагч, Хөрөнгө оруулагч</h4>
                          </div>
                          <div className="button-large">
                            <a href="/news">Блог хуудсаар зочлох</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                className="scroll-down page-scroll"
                title="Scroll Down"
                href="#intro"
              >
                <i />
              </a>
            </div>
          </div>
        </Parallax>
      </section>
    </>
  );
}
