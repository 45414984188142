import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import MarkDownIt from "markdown-it";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import FooterSection from "../home_sections/FooterSection";
import PostsContext from "../context/PostsContext";
import ApiContext from "../context/ApiContext";
import MenuResponsive from "../components/MenuResponsive";
import ClipLoader from "react-spinners/ClipLoader";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import BlogPost from "./BlogPost";

/*
    PostsContext дотор байхгүй бол server -с fetch хийнэ
  
    Search from PostsContext if not found, send http to api
  */

const paginate = 4;
export default function RelatedNews({ id }) {
  const [loading, setLoading] = useState(true);
  const { posts, _ } = useContext(PostsContext);
  const [related, setRelated] = useState([]);
  const api = useContext(ApiContext);
  const fetchRelated = async () => {
    if (posts && posts.length !== 0) {
      setRelated(posts.filter((p) => p.id !== id).slice(0, paginate));
      setLoading(false);
      return;
    }
    setLoading(true);
    const response = await api.get(`posts?_sort=id:DESC&_limit=${paginate}`);
    const data = response.data || [];
    setRelated(data.filter((p) => p.id !== id));
    setLoading(false);
  };
  useEffect(() => {
    fetchRelated();
  }, []);
  return (
    <>
      <div className="slidebar-post">
        <h3>Бусад мэдээ</h3>
        <div style={{ marginBottom: "4rem" }}></div>
        {loading ? (
          <Loading />
        ) : (
          related.map((r) => (
            <div key={r.id} onClick={() => window.scrollTo(0, 0)}>
              <BlogPost {...r} />
            </div>
          ))
        )}
      </div>
    </>
  );
}
