import React from "react";
import { Parallax } from "react-parallax";
import bg from "../assets/images/main-vertical.jpg";

export default function IntroSection(props) {
  return (
    <section id="intro" className="blog white-bg page-section">
      <Parallax
        bgImage={bg}
        strength={200}
        renderLayer={(percentage) => (
          <div
            style={{
              position: "absolute",
              background: `rgba(0, 0, 0, 0.05)`,
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          />
        )}
      >
        <div
          className="container-fluid"
          style={{ paddingLeft: 10, paddingRight: 0 }}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-8">
                <div>
                  <div className="main-timeline">
                    <div className="timeline">
                      <a href className="timeline-content">
                        <div className="timeline-icon">
                          {/* <i className="fa fa-globe" /> */}
                          <span className="ti-ruler-pencil" />
                        </div>
                        <div className="timeline-year">2004</div>
                        <div className="inner-content">
                          <h3 className="title">Ахлах сургууль</h3>
                          <p className="description">
                            1994 онд Орос 14-р сургуульд элсэн орж, улмаар 2000
                            онд АНУ -ын Калифорниа мужийн ахлах сургуульд шилжин
                            суралцаж 2004 онд төгссөн
                          </p>
                        </div>
                      </a>
                    </div>

                    <div className="timeline">
                      <a href className="timeline-content">
                        <div className="timeline-icon">
                          <span className="ti-bookmark-alt" />
                        </div>
                        <div className="timeline-year"> 2008 </div>
                        <div className="inner-content">
                          <h3 className="title">Бэнтлигийн их сургууль</h3>
                          <p className="description">
                            2008 онд Бэнтлигийн их сургуулийг санхүү, эдийн
                            засагч мэргэжлээр суралцаж төгссөн
                          </p>
                        </div>
                      </a>
                    </div>

                    <div className="timeline">
                      <a href className="timeline-content">
                        <div className="timeline-icon">
                          <span className="ti-calendar" />
                        </div>
                        <div className="timeline-year"> 2008 </div>
                        <div className="inner-content">
                          <h3 className="title">
                            Олон улсын хөрөнгө оруулалтын банкир
                          </h3>
                          <p className="description">
                            2008 онд сургуулиа төгсөөд Америкийн “Жи Пи Морган”
                            /JP Morgan/ хөрөнгө оруулалтын банкинд банкираар
                            ажлын гараагаа эхэлсэн
                          </p>
                        </div>
                      </a>
                    </div>

                    <div className="timeline">
                      <a href className="timeline-content">
                        <div className="timeline-icon">
                          <span className="ti-calendar" />
                        </div>
                        <div className="timeline-year"> 2011 </div>
                        <div className="inner-content">
                          <h3 className="title">10 тэрбум ам.доллар</h3>
                          <p className="description">
                            2008-2011 онд олон улсын хөрөнгийн зах зээл дээр 10
                            тэрбум ам.долларыг компаниудад босгож өгсөн
                          </p>
                        </div>
                      </a>
                    </div>

                    <div className="timeline">
                      <a href className="timeline-content">
                        <div className="timeline-icon">
                          <span className="ti-bar-chart" />
                        </div>
                        <div className="timeline-year"> 2012 </div>
                        <div className="inner-content">
                          <h3 className="title">
                            Монголбанкны Дэд ерөнхийлөгч
                          </h3>
                          <p className="description">
                            2012-2016 оны хооронд Монголбанкны Дэд
                            ерөнхийлөгчөөр дөрвөн жилийн хугацаанд ажилласан
                          </p>
                        </div>
                      </a>
                    </div>

                    <div className="timeline">
                      <a href className="timeline-content">
                        <div className="timeline-icon">
                          <span className="ti-stats-up" />
                        </div>
                        <div className="timeline-year"> 2016-2021 </div>
                        <div className="inner-content">
                          <h3 className="title">Бизнес</h3>
                          <p className="description">
                            2016 оноос хойш хувийн бизнесийг амжилттайгаар
                            удирдан ажиллаж байна
                          </p>
                        </div>
                      </a>
                    </div>

                    <div className="timeline">
                      <a href className="timeline-content">
                        <div className="timeline-icon">
                          <span className="ti-shine" />
                        </div>
                        <div className="timeline-year"> 2021 </div>
                        <div className="inner-content">
                          <h3 className="title">Монгол улсын их хурлын гишүүн</h3>
                          <p className="description">
                            Монгол улсын их хурлын гишүүн
                          </p>

                        </div>
                      </a>
                    </div>
                    <div className="timeline">
                      <a href className="timeline-content">
                        <div className="timeline-icon">
                          <span className="ti-stats-up" />
                        </div>
                        <div className="timeline-year"> 2022 </div>
                        <div className="inner-content">
                          <h3 className="title">Өнөөдөр</h3>
                          <p className="description">
                            2022 оноос Монгол Улсын Их хурлын Инновац, цахим бодлогын байнгын хорооны даргаар ажиллаж байна.
                          </p>
                          {/* <p className="description">
                            Боловсрол, соёл, шинжлэх ухаан, спортын байнгын
                            хорооны гишүүн
                          </p> */}
                        </div>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </section>
  );
}
