import React from "react";
import SocialIcons from "../components/SocialIcons";

export default function Footer(props) {
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="footer-column col-sm-3">
            <ul>
              <li>
                <a href="#home" className="footer-link">
                  Эхлэл
                </a>
              </li>
              <li>
                <a href="#about" className="footer-link">
                  Миний тухай
                </a>
              </li>
              <li>
                <a href="#resume" className="footer-link">
                  Товч намтар
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column col-sm-3">
            <ul>
              <li>
                <a href="#gallery" className="footer-link">
                  Зургийн цомог
                </a>
              </li>
              <li>
                <a href="#blog" className="footer-link">
                  Хувийн блог
                </a>
              </li>
              <li>
                <a href="#contact" className="footer-link">
                  Холбоо барих
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column col-sm-3">
            <ul>
              <li>
                <a href="#blog" className="footer-link">
                  Онцлох мэдээ
                </a>
              </li>
              <li>
                <a href="#contact" className="footer-link">
                  Захидал бичих
                </a>
              </li>
              <li>
                <a href="#home" className="footer-link">
                  Танилцуулга бичлэг
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column col-sm-3">
            <div className="footer-social-container">
              <SocialIcons containerStyle={{ justifyContent: "center" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
