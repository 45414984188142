import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

export default function Loading(props) {
  return (
    <div style={{ textAlign: "center" }}>
      <ClipLoader color="#000" {...props} />
    </div>
  );
}
