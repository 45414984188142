import React from "react";

export default function LoadingModal() {
  return (
    <div id="loading">
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div className="object" id="object_one" />
          <div className="object" id="object_two" />
          <div className="object" id="object_three" />
          <div className="object" id="object_four" />
        </div>
      </div>
    </div>
  );
}
