import React, { useEffect, useState } from "react";
import { withLastLocation } from "react-router-last-location";
import $ from "jquery";
import { Parallax } from "react-parallax";

import HeroSection from "../home_sections/HeroSection";
import AboutSection from "../home_sections/AboutSection";
import IntroSection from "../home_sections/IntroSection";
import BlogsSection from "../home_sections/BlogsSection";
import ContactSection from "../home_sections/ContactSection";
import StorySection from "../home_sections/StorySection";
import GallerySection from "../home_sections/GallerySection";
import Navbar from "../components/Navbar";

import bgIntro from "../assets/images/ladder-md.jpg";
import bgAbout from "../assets/images/main-crop.jpeg";
import bgGallery from "../assets/images/school-images.jpg";
import bgBlog from "../assets/images/winter-images.jpg";
import bgContact from "../assets/images/dial-sm.jpg";

import MenuResponsive from "../components/MenuResponsive";
import LoadingModal from "../components/LoadingModal";
import TestimonialsSection from "../home_sections/TestimonialsSection";
import SkillsSection from "../home_sections/SkillsSection";
import VideoSection from "../home_sections/VideoSection";
import Footer from "../components/Footer";
import { useMemo } from "react";

// import HeroBg from "../assets/img/politician-bg.jpg";

function HomePage() {
  const linkHref = (selector) => {
    $(selector).on("click", function (e) {
      if ($(e.target).is(selector)) {
        if (
          window.location.pathname.replace(/^\//, "") ==
            e.target.pathname.replace(/^\//, "") &&
          window.location.hostname == e.target.hostname
        ) {
          var target = $(e.target.hash);
          target = target.length
            ? target
            : $("[name=" + e.target.hash.slice(1) + "]");
          if (target.length) {
            var gap = 0;
            $("html,body").animate(
              {
                scrollTop: target.offset().top - gap,
              },
              900
            );
          }
        }
        return false;
      }
    });
  };

  const parallax1 = useMemo(() => {
    return (
      <div>
        <Parallax bgImage={bgIntro} strength={1000}>
          <div style={{ height: "40vh" }}></div>
        </Parallax>
      </div>
    );
  }, []);
  const parallaxStory = useMemo(() => {
    return (
      <div>
        <Parallax bgImage={bgAbout} strength={1000}>
          <div style={{ height: "40vh" }}></div>
        </Parallax>
      </div>
    );
  }, []);
  const parallaxGallery = useMemo(() => {
    return (
      <div className="parallax-gallery-responsive">
        <Parallax bgImage={bgGallery} strength={50}>
          <div style={{ height: "70vh" }}></div>
        </Parallax>
      </div>
    );
  }, []);

  const parallaxBlog = useMemo(() => {
    return (
      <div>
        <Parallax bgImage={bgBlog} strength={50}>
          <div style={{ height: "70vh" }}></div>
        </Parallax>
      </div>
    );
  }, []);

  const parallaxContact = useMemo(() => {
    return (
      <div>
        <Parallax bgImage={bgContact} strength={50}>
          <div style={{ height: "70vh" }}></div>
        </Parallax>
      </div>
    );
  }, []);

  // jquery init
  useEffect(() => {
    linkHref("a.page-scroll");
    linkHref("a.footer-link");
  }, []);
  return (
    <>
      <div>
        {/* Google Tag Manager (noscript) */}
        <noscript>
          &lt;iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-557RCPW"
          height="0" width="0"
          style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;
        </noscript>
        {/* <LoadingModal /> */}
        <Navbar
          items={["home", "about", "resume", "gallery", "blog", "contact"]}
          currentClassName="active"
        >
          <li className="active">
            <a href="#home" className="page-scroll">
              Эхлэл
            </a>
          </li>
          <li>
            <a href="#about" className="page-scroll">
              Миний тухай
            </a>
          </li>
          <li>
            <a href="#resume" className="page-scroll">
              Товч намтар
            </a>
          </li>
          <li>
            <a href="#gallery" className="page-scroll">
              Зургийн цомог
            </a>
          </li>
          {/* <li>
            <a href="#video" className="page-scroll">
              Танилцуулга бичлэг
            </a>
          </li> */}
          <li>
            <a href="#blog" className="page-scroll">
              Хувийн блог
            </a>
          </li>
          <li>
            <a href="#contact" className="page-scroll">
              Холбоо барих
            </a>
          </li>
        </Navbar>
        <section className="content-scroller">
          {/* <HeroSection /> */}
          <AboutSection />
          <IntroSection />
          <StorySection />
          {parallaxGallery}
          <GallerySection />
          {parallaxBlog}
          <BlogsSection />
          {parallaxContact}
          <ContactSection />
        </section>
      </div>
      <Footer />
    </>
  );
}

// export default withLastLocation(HomePage);
export default HomePage;
