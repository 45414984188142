import React, {
  useContext,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import BlogPost from "../components/BlogPost";
import PostsContext from "../context/PostsContext";
import Loading from "../components/Loading";
import SectionTitle from "../components/SectionTitle";

const loremIpsumLong =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";

export default function BlogSection(props) {
  const { posts } = useContext(PostsContext);
  const ready = useMemo(() => {
    return posts != null && posts.length != 0;
  }, [posts]);
  const content = useMemo(() => {
    if (!ready) {
      return (
        <div
          style={{
            width: "100%",
            height: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      );
    }
    return (
      <div
        className="row d-flex flex-wrap justify-content-start"
        // style={{
        //   display: "flex",
        //   justifyContent: "flex-start",
        //   flexWrap: "wrap",
        // }}
      >
        {posts.map((p) => (
          <div
            key={p.id}
            className="col-sm-6 col-md-6 col-lg-4 col-xl-3 flex-sm-fill mb-4"
          >
            <BlogPost {...p} />
          </div>
        ))}
      </div>
    );
  });
  // col-lg-3 col-md-6 col-sm-6
  // col-sm-8 col-md-6 col-lg-4 mb-4
  return (
    <section
      id="blog"
      className="blog white-bg page-section"
      data-aos="fade-up"
      data-aos-duration="400"
    >
      <div className="container-fluid">
        <SectionTitle title="Хувийн блог" subtitle="Мэдээ" />
        {content}
        <div style={{ textAlign: "center" }}>
          <div className="button-large">
            <Link to="/news">Блог хуудсаар зочлох</Link>
          </div>
        </div>
      </div>
    </section>
  );
}
