import React from "react";
import ContactItem from "../components/ContactItem";
import SocialIcons from "../components/SocialIcons";
import AOS from "aos";
import { useEffect } from "react/cjs/react.development";
import zurag1 from "../assets/images/zurag1.jpeg";
import QR from "../assets/images/BatshugarQR.png";

export default function AboutSection(props) {
  return (
    <section
      id="about"
      className="about white-bg page-section"
      style={{ marginBottom: "5rem" }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="about-image">
              <img
                className="img-responsive"
                src={zurag1}
                style={{ width: "100%", height: "auto" }}
                alt="Profile"
              />
            </div>
            <div className="about-social">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/e.batshugar"
                    target="_blank"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/batshugare" target="_blank">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/e.batshugar/"
                    target="_blank"
                  >
                    <i className="fa fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
            <div className="section-title" style={{ marginBottom: "0" }}>
              <div className="section-title-name">
                <div>
                  <span style={{ fontSize: "1.5rem" }}>Миний тухай</span>
                  <h2 style={{ fontStyle: "italic" }}>Миний тухай</h2>
                  <div style={{ marginTop: "2rem" }}>
                    <SocialIcons />
                  </div>
                </div>
              </div>
              <div className="title-name-gray">
                {/* <strong>Миний тухай</strong> */}
              </div>
            </div>
            <div className="contact-block">
              <div className="row">
                <div className="col-sm-5">
                  <div className="my-contact clearfix">
                    <div className="contact-icon">
                      <span className="ti-user" />
                    </div>
                    <div className="contact-info">
                      <h4>Нэр:</h4>
                      <p>БАТШУГАР Энхбаяр</p>
                    </div>
                  </div>
                  <div className="my-contact clearfix">
                    <div className="contact-icon">
                      <span className="ti-calendar" />
                    </div>
                    <div className="contact-info">
                      <h4>Төрсөн он:</h4>
                      <p>1987 он</p>
                    </div>
                  </div>
                  {/* <div className="my-contact clearfix">
                    <div className="contact-icon">
                      <span className="ti-flag-alt-2" />
                    </div>
                    <div className="contact-info">
                      <h4>Яс үндэс:</h4>
                      <p>ХАЛХ</p>
                    </div>
                  </div> */}
                </div>
                <div className="col-sm-5">
                  <div className="my-contact clearfix">
                    <div className="contact-icon">
                      <span className="ti-email" />
                    </div>
                    <div className="contact-info">
                      <h4>Цахим шуудан:</h4>
                      <p>info@batshugar.com</p>
                    </div>
                  </div>
                  <div className="my-contact clearfix">
                    <div className="contact-icon">
                      <span className="ti-briefcase" />
                    </div>
                    <div className="contact-info">
                      <h4>Мэргэжил:</h4>
                      <p>Санхүүч, Эдийн засагч</p>
                    </div>
                  </div>
                  {/* <div className="my-contact clearfix">
                    <div className="contact-icon">
                      <span className="ti-direction-alt" />
                    </div>
                    <div className="contact-info">
                      <h4>Ажлын хаяг:</h4>
                      <p className="justified-text">
                        Гэрэгэ цамхаг, Чингисийн өргөн чөлөө, Сүхбаатар дүүрэг,
                        Улаанбаатар хот, Монгол улс
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="row">
             
              <img
                className="img-responsive"
                src={QR}
                style={{ width: "300px", height: "auto", marginTop: "50px" }}
                alt="Profile"
              />
              </div>
              {/* <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="about-block clearfix">
                    <p className="justified-text">
                      Миний бие Энхбаяр овогтой Батшугар нь 1987 онд Монгол
                      улсын нийслэл Улаанбаатар хотноо мэндэлсэн билээ.
                    </p>
                    <p className="justified-text">
                      Бага нас болон сургуулийн насны амьдрал минь АНУ -ын
                      Калифорни мужийн ахлах сургууль, оюутан насандаа АНУ -ын
                      Массачусетс мужийн Уолтхэмд байрлах Бэнтлигийн их
                      сургуульд санхүүч, эдийн засагч мэргэжлээр суралцан
                      амжилттай төгсжээ.
                    </p>
                    <p className="justified-text">
                      Ажлын гараагаа АНУ-с эхлүүлэн “Жи Пи Морган” /JP Morgan/
                      хөрөнгө оруулалтын банканд 2008-2011 онд ажилласан 3 жил,
                      Монголбанк-ны Дэд ерөнхийлөгчөөр 2012-2016 онд ажилласан 4
                      жил болон түүнээс хойш бизнес эрхэлсэн он жилүүд гэх
                      мэтчилэн өнөөдрийг хүртэл санхүү болон бизнесийн салбарт
                      тасралтгүй туршлага хуримтлуулсаар байна.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
