import React from "react";
import MenuResponsive from "./MenuResponsive";
import ScrollSpy from "react-scrollspy";
import profileImg from "../assets/images/right-crop1.jpg";

const linkStyle = { textTransform: "uppercase" };
export default function MainMenu(props) {
  const { items, currentClassName } = props;
  return (
    <>
      <MenuResponsive />
      <header id="left-header" className="header">
        <nav id="menu" className="navbar navbar-default">
          <div className="navbar-header">
            <img
              id="logo_img"
              className="img-responsive"
              src="/images/chief-profile.jpg"
              alt=""
              style={{ width: "80%", margin: "auto" }}
            />{" "}
          </div>
          <div className="collapse navbar-collapse navbar-ex1-collapse">
            <ScrollSpy
              currentClassName={currentClassName}
              items={items}
              className="nav navbar-nav"
            >
              {props.children}
            </ScrollSpy>
          </div>
        </nav>
        {/* menu footer */}
        <div className="menu-footer">
          <div className="social">
            <ul>
              <li>
                <a href="https://twitter.com/batshugare" target="_blank">
                  <i className="fa fa-facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/e.batshugar/"
                  target="_blank"
                >
                  <i className="fa fa-twitter" />
                </a>
              </li>
              <li className="instagram">
                <a
                  href="https://www.instagram.com/e.batshugar/"
                  target="_blank"
                >
                  <i className="fa fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
          <a href="http://remif.mn" target="_blank">
            <div className="copyright">
              <p>
                <div>Developed by</div>
                <div style={{ fontSize: 12 }}>© Remif Technologies LLC</div>
                <div style={{ textTransform: "uppercase" }}>
                  all rights reserved
                </div>
              </p>
            </div>
          </a>
        </div>
      </header>
    </>
  );
}
