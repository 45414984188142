import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { Blurhash } from "react-blurhash";
import { formatDate } from "../utils";

export default function BlogPost({
  id,
  title,
  body,
  photo,
  updated_at,
  post_date,
  facebook_medias,
}) {
  const url = useMemo(() => {
    return `/news/${id}`;
  }, [id]);
  const fullUrl = useMemo(() => {
    return `${process.env.REACT_APP_HOST_NAME}${url}`;
  }, [url]);

  const imageUrl = useMemo(() => {
    if (photo && photo.length > 0) {
      return `${process.env.REACT_APP_API_URL}${photo[0].url}`;
    }
    if (!facebook_medias) {
      return false;
    }
    return facebook_medias[0]?.url;
  }, []);

  const image = useMemo(() => {
    if (!imageUrl || !imageUrl.length) {
      return (
        <Blurhash
          hash="L4MHJjxuM{_3~q_3-;IUxu-;D%D%"
          width={"100%"}
          height={"30vh"}
        />
      );
    }
    return (
      <img className="img-responsive blog-news-image" src={imageUrl} alt="" />
    );
  }, [imageUrl]);

  const dateStr = useMemo(() => {
    return formatDate(post_date);
  }, [updated_at]);

  return (
    <Link to={url}>
      <div
        className="blog-post-big"
        data-aos="fade-left"
        data-aos-duration="600"
      >
        <div className="blog-block">
          <div className="blog-image">
            {image}
            <div className="blog-date">
              {/* <span>
            June <br />
            05
          </span> */}
            </div>
          </div>
          <div className="blog-info blog-news-info" style={{ width: "100%" }}>
            <div
              className="blog-meta"
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {/* <p>
              <span className="ti-comment-alt" /> <a href="#">4</a>
            </p> */}
              {/* <p style={{ marginRight: "1rem" }}>
              <span className="ti-user" />{" "}
              <a href="#" onClick={(e) => e.preventDefault()}>
                Admin{" "}
              </a>
            </p> */}
              <p style={{ marginRight: "1rem" }}>
                <span className="ti-folder" />{" "}
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Мэдээ
                </a>
              </p>
              <p style={{ flexGrow: 1, textAlign: "end" }}>
                <span className="text-primary">{dateStr}</span>
              </p>
            </div>
            <div className="blog-content">
              <h4>{title}</h4>
              <p className="blog-text-short">{body}</p>
              <div className="blog-bottom clearfix">
                <div className="button-small">
                  <Link to={url}>Дэлгэрэнгүй</Link>
                </div>
                <div className="social pull-right">
                  {/* <p>{new Date(updated_at).toLocaleDateString()}</p> */}
                  <ul>
                    <li>
                      <FacebookShareButton url={fullUrl}>
                        <a onClick={() => false}>
                          <i className="fa fa-facebook" />{" "}
                        </a>
                      </FacebookShareButton>
                    </li>
                    <li>
                      <TwitterShareButton url={fullUrl}>
                        <a onClick={() => false}>
                          <i className="fa fa-twitter" />
                        </a>
                      </TwitterShareButton>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
