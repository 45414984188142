import React, { useContext, useEffect, useState } from "react";
import GalleryItem from "../components/GalleryItem";
import Gallery from "react-grid-gallery";
import ImageGallery from "react-image-gallery";
import ApiContext from "../context/ApiContext";
import Loading from "../components/Loading";
import SectionTitle from "../components/SectionTitle";

export default function GallerySection(props) {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = useContext(ApiContext);
  const fetchPhotos = async () => {
    setLoading(true);
    const response = await api.get("/gallery");
    const { photo } = response.data;
    let photos = [];
    for (let p of photo) {
      let url;
      if (p.formats.small) {
        url = p.formats.small.url;
      }
      if (p.formats.medium) {
        url = p.formats.medium.url;
      }
      if (p.formats.large) {
        url = p.formats.large.url;
      }
      if (url) {
        photos.push({
          src: `${process.env.REACT_APP_API_URL}${url}`,
          thumbnail: `${process.env.REACT_APP_API_URL}${url}`,
          thumbnailWidth: p.formats.thumbnail.width,
          thumbnailHeight: p.formats.thumbnail.height,
        });
        // photos.push({
        //   original: `${process.env.REACT_APP_API_URL}${url}`,
        //   thumbnail: `${process.env.REACT_APP_API_URL}${p.formats.thumbnail.url}`,
        // });
      }
    }
    setPhotos(photos);
    setLoading(false);
  };
  useEffect(() => {
    fetchPhotos();
  }, []);

  return (
    <section
      id="gallery"
      className="portfolio white-bg page-section"
      data-aos="fade-up"
      data-aos-duration="550"
    >
      <div className="container-fluid">
        <SectionTitle title="Зургийн цомог" subtitle="Зураг" />
        <div className="row wrap" style={{ marginLeft: 0, marginRight: 0 }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <Loading />
            </div>
          ) : (
            <div className="gallery-container">
              <Gallery margin={0} images={photos} />
              {/* <ImageGallery items={photos} /> */}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
