import React from "react";

export default function SectionTitle({
  title,
  subtitle,
  children,
  marginBottom,
}) {
  return (
    <>
      <div
        className="section-title-container"
        style={{ marginBottom: marginBottom || "5rem" }}
      >
        {subtitle ? (
          <div className="section-title-name">
            <span>{subtitle}</span>
          </div>
        ) : (
          <></>
        )}
        <h1 style={{ fontWeight: "bold", fontStyle: "italic" }}>{title}</h1>
        <div>{children}</div>
      </div>
    </>
  );
}
