import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import AOS from "aos";
import Switch from "react-router-transition-switch";
import Fader from "react-fader";

import axios from "axios";
import ApiContext from "./context/ApiContext";
import HomePage from "./pages/HomePage";
import BlogSinglePage from "./pages/BlogSinglePage";
import BlogsAllPage from "./pages/BlogsAllPage";
import PostsContext from "./context/PostsContext";
import NotFoundPage from "./pages/NotFoundPage";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

function App() {
  const [posts, setPosts] = useState([]);
  const [postCount, setPostCount] = useState(0);
  const fetchPosts = async () => {
    try {
      const postResponse = await apiClient.get(
        `posts?_sort=id:DESC&_limit=${process.env.REACT_APP_PAGINATION}`
      );
      const data = postResponse.data ?? [];
      const countResponse = await apiClient.get("posts/count");
      setPostCount(
        Math.ceil(countResponse.data / process.env.REACT_APP_PAGINATION) || 1
      );
      setPosts(data);
    } catch (e) {}
  };

  useEffect(() => {
    fetchPosts();
    return () => console.log("APP.js unmount");
  }, []);

  // aos init
  useEffect(() => {
    AOS.init({ duration: 2000, once: true });
  }, []);

  return (
    <>
      <ApiContext.Provider value={apiClient}>
        <PostsContext.Provider
          value={{ posts, setPosts, postCount, fetchPosts }}
        >
          <Router>
            <Switch component={Fader}>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route key="news" exact path="/news">
                <BlogsAllPage />
              </Route>
              <Route key="news" path="/news/page/:page?">
                <BlogsAllPage />
              </Route>
              <Route path="/news/:id">
                <BlogSinglePage />
              </Route>
              <Route exact path="/404">
                <NotFoundPage />
              </Route>
            </Switch>
          </Router>
        </PostsContext.Provider>
      </ApiContext.Provider>
    </>
  );
}

export default App;
