import React from "react";

export default function ContactItem({ title, content, icon }) {
  return (
    <div className="col-lg-4 col-md-4 col-sm-4 mb-1">
      <div className="row">
        <div className="col-md-3 contact-icon align-self-center">{icon}</div>
        <div className="col-md-9 d-flex flex-direction-column">
          <div className="contact-info">
            <h4>
              <b>{title}</b>
            </h4>
            <p>{content}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
