import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import ReactPaginate from "react-paginate";
import BlogPost from "../components/BlogPost";
import ApiContext from "../context/ApiContext";
import PostsContext from "../context/PostsContext";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import SectionTitle from "../components/SectionTitle";
import Footer from "../components/Footer";

const calcPageCount = (postCount) => {
  return Math.ceil(postCount / process.env.REACT_APP_PAGINATION) || 1;
};
export default function BlogsAllPage(props) {
  const { posts, setPosts, postCount, fetchPosts } = useContext(PostsContext);
  const params = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(!posts);
  const api = useContext(ApiContext);

  const [pageCount, setPageCount] = useState(postCount);

  const fetchPage = async (page) => {
    try {
      const response = await api.get(
        `/posts?_sort=id:DESC&_start=${
          process.env.REACT_APP_PAGINATION * (page - 1)
        }&_limit=${process.env.REACT_APP_PAGINATION}`
      );
      if (!response.data || response.data.length == 0) {
        history.push("/404");
        return;
      }
      if (!pageCount) {
        const postCountResponse = await api.get("/posts/count");
        const pageCount = calcPageCount(postCountResponse.data);
        setPageCount(pageCount);
      }
      setPosts(response.data);
      setLoading(false);
    } catch (e) {}
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!posts || posts.length == 0) {
      fetchPage(params?.page || 1);
    }
  }, [posts]);

  // dynamic pagination
  const handlePagination = async (page) => {
    if (
      params.page == page ||
      (page == 1 &&
        window.location.href === `${process.env.REACT_APP_HOST_NAME}/news`)
    ) {
      return;
    }
    history.push(`/news/page/${page}`);
    setLoading(true);
    fetchPage(page);
  };
  const content = useMemo(() => {
    if (loading || !posts) {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            padding: "10%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      );
    }
    return posts.map((p) => (
      <div key={p.id} className="col-sm-6 col-md-4 col-lg-4 flex-sm-fill">
        <BlogPost {...p} />
      </div>
    ));
  }, [loading, posts]);

  // col-lg-3 col-md-6 col-sm-6
  // col-sm-8 col-md-6 col-lg-4 mb-4
  return (
    <>
      <div>
        <Navbar>
          <li>
            <Link to="/" onClick={() => fetchPosts()}>
              Эхлэл
            </Link>
          </li>
          <li className="active">
            <Link to="/news">Мэдээ</Link>
          </li>
          <li>
            <a href="#about" className="page-scroll">
              Миний тухай
            </a>
          </li>
          <li>
            <a href="#resume" className="page-scroll">
              Товч намтар
            </a>
          </li>
          <li>
            <a href="#gallery" className="page-scroll">
              Зургийн цомог
            </a>
          </li>
        </Navbar>
        <section id="blog" className="content-scroller white-bg page-section">
          <div className="container-fluid">
            <SectionTitle title="Хувийн блогоос" subtitle="Мэдээ">
              {/* <div
              style={{
                position: "fixed",
                top: 10,
                right: 10,
                color: "black",
              }}
            >
              <input placeholder="Мэдээ хайх" />
            </div> */}
            </SectionTitle>
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              {content}
            </div>
            {loading ? (
              <></>
            ) : (
              <div style={{ textAlign: "center" }}>
                <ReactPaginate
                  initialPage={params.page ? params.page - 1 : 0}
                  previousLabel={"Өмнөх"}
                  nextLabel={"Дараагийнх"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={({ selected }) => {
                    handlePagination(selected + 1);
                  }}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            )}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
