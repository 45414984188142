import React from "react";
import SectionTitle from "../components/SectionTitle";

export default function TestimonialsSection(props) {
  return (
    <section
      id="testimonials"
      className="testimonials white-bg page-section-pt"
    >
      <div className="container-fluid">
        <SectionTitle title="Магтаал сайшаал" subtitle="Магтаал" />
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="testimonials-block">
              <div className="testimonials-content">
                <div className="testimonials-avtar">
                  <img
                    className="imgresponsive"
                    src="images/testimonials/01.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonials-comment">
                  <p>
                    Are you considering buying a compatible inkjet cartridge for
                    your printer? There are many reputed companies like Canon,
                    Epson, Dell, and Lexmark{" "}
                  </p>
                </div>
              </div>
              <div className="testimonials-name">
                <h4>Michael Bean</h4>
                <span>Project manager</span>
              </div>
            </div>
            <div className="testimonials-block">
              <div className="testimonials-content">
                <div className="testimonials-avtar">
                  <img
                    className="imgresponsive"
                    src="images/testimonials/02.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonials-comment">
                  <p>
                    {" "}
                    Sports betting While most people enjoy casino gambling,
                    lottery and bingo playing for the fun and excitement it
                    provides, others may experience gambling as an addictive and
                    distractive habit.{" "}
                  </p>
                </div>
              </div>
              <div className="testimonials-name">
                <h4>Joana Williams</h4>
                <span>Executive Director</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="testimonials-block">
              <div className="testimonials-content">
                <div className="testimonials-avtar">
                  <img
                    className="imgresponsive"
                    src="images/testimonials/03.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonials-comment">
                  <p>
                    While most people enjoy casino gambling, sports betting,
                    lottery and bingo playing for the fun and excitement it
                    provides, others may experience gambling as an addictive and
                    distractive habit.{" "}
                  </p>
                </div>
              </div>
              <div className="testimonials-name">
                <h4>Paul Flavius</h4>
                <span>Managing Director</span>
              </div>
            </div>
            <div className="testimonials-block">
              <div className="testimonials-content">
                <div className="testimonials-avtar">
                  <img
                    className="imgresponsive"
                    src="images/testimonials/04.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonials-comment">
                  <p>
                    Despite the advancements few would argue that, of feminism
                    over the past three{" "}
                  </p>
                </div>
              </div>
              <div className="testimonials-name">
                <h4>Felica Queen</h4>
                <span>Department Head</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="testimonials-block">
              <div className="testimonials-content">
                <div className="testimonials-avtar">
                  <img
                    className="imgresponsive"
                    src="images/testimonials/05.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonials-comment">
                  <p>
                    Few would argue that, despite the advancements of feminism
                    over the past three{" "}
                  </p>
                </div>
              </div>
              <div className="testimonials-name">
                <h4>Michael Bean</h4>
                <span>Team Leader</span>
              </div>
            </div>
            <div className="testimonials-block">
              <div className="testimonials-content">
                <div className="testimonials-avtar">
                  <img
                    className="imgresponsive"
                    src="images/testimonials/06.jpg"
                    alt=""
                  />
                </div>
                <div className="testimonials-comment">
                  <p>
                    Today, many people rely on computers to do homework, work,
                    and create or store useful information.
                  </p>
                </div>
              </div>
              <div className="testimonials-name">
                <h4>Mellissa Doe</h4>
                <span>Section Head</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
