import React, { useState } from "react";
import SectionTitle from "../components/SectionTitle";
import ReactPlayer from "react-player";
import bg from "../assets/images/bg-winter.jpeg";
import { Blurhash } from "react-blurhash";

export default function VideoSection() {
  return (
    <div
      className="container-fluid"
      style={{ position: "relative", paddingTop: "56.25%" }}
    >
      <div
        className="blur"
        style={{
          height: "100%",
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
        }}
      >
        <Blurhash
          hash={"LNONUlM{D*IU~qNHof-;D*%MMwMx"}
          width={"100%"}
          height={"100%"}
        />
      </div>
      {/* <div style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
        <ReactPlayer
          width={"100%"}
          height={"auto"}
          url={"https://www.facebook.com/e.batshugar/videos/333712731249188"}
        />
      </div> */}
    </div>
  );
}
