import React from "react";
import Footer from "../components/Footer";

export default function NotFoundPage() {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        Хуудас олдсонгүй
      </div>
      <Footer />
    </>
  );
}
