import React from "react";
import SectionTitle from "../components/SectionTitle";

export default function StorySection(props) {
  return (
    <section id="resume" className="resume white-bg page-section">
      <div className="container-fluid">
        <SectionTitle title="Товч намтар" subtitle="Намтар" />
        <div
          className="resume-block"
          data-aos="fade-up"
          data-aos-duration="550"
        >
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="resume-icon">
                <span className="ti-ruler-pencil" />
              </div>
              <div className="resume-name">
                <h3>Ахлах сургууль</h3>
                <span>1994-2004 он</span>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="resume-info">
                <h3>Ахлах сургууль</h3>
                <p className="justified-text">
                  1994 онд орос 14-р сургуульд элсэн орж, улмаар 2000 онд АНУ
                  -ын Калифорниа мужийн ахлах сургуульд шилжин суралцсан. Ахлах
                  сургуулиа амжилттай дүүргэсний дараа буюу 2004 онд АНУ -ын
                  Бэнтлигийних сургуульд элсэн орсон минь миний амьдралыг эдийн
                  засаг, санхүү, хөрөнгийн зах зээлийн ертөнцтэй холбож өгсөн
                  юм.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="resume-block"
          data-aos="fade-up"
          data-aos-duration="550"
        >
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="resume-icon">
                <span className="ti-bookmark-alt" />
              </div>
              <div className="resume-name">
                <h3>Оюутан нас</h3>
                <span>2004-2008</span>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="resume-info">
                <h3>Оюутан нас</h3>
                <p className="justified-text">
                  Ахлах сургуулиа амжилттай дүүргэсний дараа буюу 2004 онд АНУ
                  -ын Бэнтлигийн их сургуульд элсэн орж 2008 онд Бэнтлигийн их
                  сургуулийг санхүү, эдийн засагч мэргэжлээр суралцаж төгссөн.
                  Оюутан байхдаа сургуулийнхаа Эдийн засагчдын клуб, хувьцааны
                  хөрөнгө оруулагчдын клуб зэрэг хичээлээс гадуурх клубуудэд
                  идэвхтэй хамрагдаж байсан. Мөн эр зориг, хүч чадал, авхаалж
                  самбаа шаарддаг регби спортоор хичээллэдэг бөгөөд 2005-2008
                  онд сургуулийнхаа регби спортын шигшээ багт тоглодог байсан,
                  2006-2008 онуудад дараалан Америкийн Их Дээд сургуулиудын
                  аваргаар шалгарч байжээ.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="resume-block"
          data-aos="fade-up"
          data-aos-duration="550"
        >
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="resume-icon">
                <span className="ti-calendar" />
              </div>
              <div className="resume-name">
                <h3>Ажлын гараа</h3>
                <span>2008-2011 он</span>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="resume-info">
                <h3>Олон Улсын хөрөнгө оруулалтын банкир болов</h3>
                <p className="justified-text">
                  2008 онд сургуулиа төгсөөд Америкийн “Жи Пи Морган” /JP
                  Morgan/ хөрөнгө оруулалтын банкинд банкираар ажлын гараагаа
                  эхэлж улмаар Английн Лондон хотын салбарт шилжин ажилласан.
                  Ийн ажиллахдаа хувьцааг анхдагч зах зээлд гаргах /IPO/, бонд
                  гаргах, санхүүгийн үүсмэл хэрэгслүүдийг ашиглан хөрөнгийн эх
                  үүсвэр шаардлагатай харилцагчидтай илүү түлхүү ажиллаж байсан.
                  ОХУ, Африк, Азийн орнууд, Арабын орнуудын уул уурхай тэр
                  дундаа газрын тосны чиглэлийн компаниудтай ихэвчлэн хамтарч
                  ажилладаг байжээ. Дөнгөж 23 настай Э.Батшугар 2008-2011 онд
                  олон улсын хөрөнгийн зах зээл дээр 10 тэрбум ам.долларыг
                  компаниудад босгож өгсөн байна.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="resume-block"
          data-aos="fade-up"
          data-aos-duration="550"
        >
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="resume-icon">
                <span className="ti-flag-alt" />
              </div>
              <div className="resume-name">
                <h3>Монголдоо ирэв</h3>
                <span>2011 он</span>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="resume-info">
                <h3>Нийгмийн идэвхтэн болсон он жил</h3>
                <p className="justified-text">
                  Олон улсын зах зээлд санхүүгийн чиглэлээр амжилттай суралцаж,
                  ажлын туршлага хуримтлуулаад ирсэн тэрээр 2011 онд Монголдоо
                  ирж, тухайн үед намын нэрээ сэргээж аваад байсан МАХН-д элсэн
                  орсон. 2011-2012 оны аравдугаар сар хүртэл "МАХН"-ын VI бага
                  чуулганаас "МАХН"-ын гадаад харилцааны нарийн бичиг, Гүйцэтгэх
                  товчооны гишүүнээр сонгогдон, "МҮАН"-тай хамтарсан Шударга ёс
                  эвслийн удирдах зөвлөлийн гишүүн, "МАХН"-ын АШЁМЗХ-ны дэд
                  тэргүүн зэрэг албыг хашжээ.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="resume-block"
          data-aos="fade-up"
          data-aos-duration="550"
        >
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="resume-icon">
                <span className="ti-bar-chart" />
              </div>
              <div className="resume-name">
                <h3>Монголбанкны Дэд ерөнхийлөгч</h3>
                <span>2012-2016 он</span>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="resume-info">
                <h3>Банкны системд реформ хийсэн он жилүүд</h3>
                <p className="justified-text">
                  2012-2016 оны хооронд Монголбанкны Дэд ерөнхийлөгчөөр дөрвөн
                  жилийн хугацаанд ажилласан. Энэ хугацаанд багийнхантайгаа
                  хамтран санхүүгийн зах зээл, банкны системд цоо шинэ
                  реформуудыг амжилттай хийж хэрэгжүүлэхэд гар бие оролцсон юм.
                  Заримаас нь дурдхад төлбөр тооцооны болон картны нэгдсэн
                  сүлжээг бий болгосон явдал юм. Ингэснээр аль ч банкны ПОС
                  машин болон АТМ -уудыг ашиглан картын гүйлгээ хийгддэг, мөн
                  бидний нэрлэж заншсанаар мобайль банк буюу гар утсаараа
                  дамжуулан банк доторх болон банк хоорондын шилжүүлэг хийх
                  боломжтой болсон. Улмаар картаа ашиглан онлайн худалдаа хийдэг
                  дэлхийн стандартыг нэвтрүүлснээр иргэд зөвхөн Монгол биш
                  дэлхийн зах зээлээс худалдан авалт хийх боломжтой болсон юм.
                  Түүнчлэн Дэлхийн санхүүгийн зах зээлд хэрэглэгддэг “Mortgage
                  loan” буюу ипотекийн зээлийн тогтолцоог Монголд анх удаа бий
                  болгов. Ингэснээр: орон сууцны 4-5 жилд, жилийн 20-25%-н
                  хүүтэй олгодог байсан банкны зээлийн систем эрс өөрчлөгдөн,
                  зээлийн хугацаа 20-25 жил, жилийн 8%-ийн хүүтэйгээр зээл олгож
                  эхэлсэн юм. Үүний үр дүнд маш олон хүнийг орон сууцтай болох
                  боломжийг ийнхүү нээж өгчээ.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="resume-block"
          data-aos="fade-up"
          data-aos-duration="550"
        >
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="resume-icon">
                <span className="ti-stats-up" />
              </div>
              <div className="resume-name">
                <h3>Бизнес</h3>
                <span>2016 - 2021 он</span>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="resume-info">
                <h3>Бизнесийн салбар</h3>
                <p className="justified-text">
                  2016 оноос хойш хувийн бизнесийг амжилттайгаар удирдан ажиллаж
                  байна. Заримыг нэг салбар чиглэлээр дурдвал: Эрүүл мэндийн
                  салбар дахь гадаадын хөрөнгө оруулалттай “Монсамил” компанийн
                  ТУЗ-ийн гишүүн, Хэвлэл мэдээллийн салбарын “Медиа холдинг”
                  компанийн ТУЗ-ийн гишүүн, Санхүүгийн салбарын “Милленниум
                  капитал” компанийн ТУЗ-ийн гишүүнээр ажиллаж байна. Үүний
                  хажуугаар МАХН -ын Сонгинохайрхан дүүргийн мянгатын даргаар
                  ажиллаж байна. 
                </p>
              </div>
              <div style={{ marginTop: "20px"}} className="resume-info">
                <h3>Хобби</h3>
                <p className="justified-text">
                  Э.Батшугарын өөрийн нэрлэсэн “Их ажлын жилүүд”
                  буюу 2008-2011 онд бий болсон бага унтдаг хэвшил нь түүнийг
                  өнөөдөр олон хоббитой болгожээ. Тэрээр фитнессээр байнга
                  хичээллэж, дугуйн спорт, ууланд авиралтын спортод хоббитой
                  болж, улмаар Монголын хамгийн өндөр гурван оргил болох Алтай
                  Таванбогд, Мөнх хайрхан, Сутай хайрханд амжилттай авиралт
                  хийсэн туршлагатай уулчин болжээ. Мөн Монгол орныхоо байгалийн
                  үзэсгэлэнт газруудаар аялах дуртай, монгол ахуй зан заншил,
                  мөн Монгол хүн болж төрснөөрөө бахархдаг нэгэн билээ. Тэр
                  Орос, англи хэлийг төгс эзэмшсэн, эдийн засаг, санхүү эдийн
                  засгийн өндөр боловсролтой, хашиж байсан ажил тушаалын хүрээнд
                  амжилттай гүйцэтгэсэн тодорхой ажлуудтай гэдгээрээ нийгэм,
                  олон нийтэд танигдаж, төр, гадаад, дотоодын томоохон
                  компаниудад ажиллаж ирсэн туршлагатай нэгэн.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="resume-block"
          data-aos="fade-up"
          data-aos-duration="550"
        >
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="resume-icon">
                <span className="ti-shine" />
              </div>
              <div className="resume-name">
                <h3>Өнөөдөр</h3>
                <span>2021 -с одоо</span>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="resume-info">
                <h3>Улс төр</h3>
                <p className="justified-text">Монгол Улсын Их Хурлын Гишүүн</p>

                <p className="justified-text">
                  Инновац,  цахим бодлогын байнгын хорооны даргаар ажиллаж  “Цахим үндэстэн” болох хууль, эрх зүйн орчныг бүрдүүлэхэд санаачилга гаргаж, холбогдох хууль тогтоомжуудыг шуурхай  баталж, хэрэгжүүлж ажиллаж байна.
                </p>
                <p className="justified-text">
                  Банк санхүү, технологийн  салбарын  компаниудыг дэмжих тогтолцоог бүрдүүлэхэд анхаарч ажилласан.
                </p>
                <p className="justified-text">
                  Уул уурхайн бүтээгдэхүүний худалдааг ил тод болгох, биржээр худалдаалах үйл ажиллагааг хуулиар дэмжсэн.
                </p>
                <p className="justified-text">
                  УИХ-аас баталсан  Хөгжлийн банкны хянан шалгах түр хорооны гишүүнээр ажиллаж зээлийн эргэн төлөлт,  зарцуулалтад хяналт тавьж,  банкны үйл ажиллагааг хэвийн болгох нөхцлийг бүрдүүлсэн.
                </p>
                <h3 style={{ marginTop: "20px"}}>Э.Батшугар гишүүний ажилласан онцлох хуулиудаас:</h3>
                <p className="justified-text">
                  Виртуал хөрөнгийн үйлчилгээ үзүүлэгчийн тухай хууль
                </p>
                <p className="justified-text">
                  Нийтийн мэдээллийн ил тод байдлын тухай хууль
                </p>
                <p className="justified-text">
                  Хүний хувийн мэдээлэл хамгаалах тухай хууль
                </p>
                <p className="justified-text">
                  Цахим гарын үсгийн тухай хууль
                </p>
                <p className="justified-text">
                  Цахим-Үндэстэн эрх зүйн реформыг удаашруулж байсан 109 хуульд нэмэлт, өөрчлөлт оруулах тухай хууль
                </p>
                <p className="justified-text">
                  Нийтийн албанд  нийтийн болон хувийн ашиг сонирхлыг зохицуулах, ашиг сонирхлын зөрчлөөс урьдчилан сэргийлэх тухай хууль
                </p>
                <p className="justified-text">
                  Төрийн албан хаагчийн ёс зүйн тухай хууль
                </p>
                <p className="justified-text">
                  Төсвийн тухай хуульд нэмэлт өөрчлөлт оруулах тухай хууль
                </p>
                <p className="justified-text">
                  Боловсролын ерөнхий хууль
                </p>
                <p className="justified-text">
                  Үйлдвэрлэл, технологийн паркийн эрх зүйн байдлын тухай хууль зэрэг олон чухал хуулийн төслүүд дээр  оролцон ажиллаж батлуулсан.
                </p>

                {/* <p className="justified-text">
                  Боловсрол, соёл, шинжлэх ухаан, спортын байнгын хорооны гишүүн
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
