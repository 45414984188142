import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import $ from "jquery";

function MenuResponsive() {
  const [active, setActive] = useState(false);
  const menuIcon = useRef();
  useEffect(() => {
    if ($(window).width() > 992) {
      $("#menu-icon").removeClass("active");
      $("#left-header").animate(
        {
          "margin-left": 0,
        },
        300
      );
    }
    // $(window).resize(function () {
    //   if ($(window).width() > 992) {
    //     $("#menu-icon").removeClass("active");
    //     $("#left-header").animate(
    //       {
    //         "margin-left": 0,
    //       },
    //       300
    //     );
    //   }
    // });
  }, []);
  const handleToggle = (e) => {
    e.preventDefault();
    setActive(!active);
    let leftMenu = document.getElementById("left-header");
    leftMenu.classList.toggle("left-header-active");
    return false;
  };
  return (
    <div className="menu-responsive">
      <a href="#">
        {" "}
        <b>Э. Батшугар</b>
      </a>{" "}
      <a
        id="menu-icon"
        ref={menuIcon}
        className={classnames("but", { active: active })}
        onClick={handleToggle}
      >
        <span className="ti-menu" />{" "}
      </a>
    </div>
  );
}

export default MenuResponsive;
