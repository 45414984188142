import React from "react";

export default function SocialIcons(props) {
  return (
    <div
      className="my-social-icons"
      style={{ alignItems: "center", ...props.containerStyle }}
    >
      <ul>
        <li className="facebook">
          <a href="https://www.facebook.com/e.batshugar" target="_blank">
            <i className="fa fa-facebook" />
          </a>
        </li>
        <li className="twitter">
          <a href="https://twitter.com/batshugare" target="_blank">
            <i className="fa fa-twitter" />
          </a>
        </li>
        <li className="instagram">
          <a href="https://www.instagram.com/e.batshugar/" target="_blank">
            <i className="fa fa-instagram" />
          </a>
        </li>
      </ul>
    </div>
  );
}
