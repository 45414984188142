import React, { useRef, useState } from "react";
import { Parallax } from "react-parallax";
import SectionTitle from "../components/SectionTitle";
import Modal from "react-modal";
import bg from "../assets/images/pen-note.jpg";

export default function ContactSection(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sendInProgress, setSendInProgress] = useState(false);
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const phoneRef = useRef();

  const handleSubmit = () => {
    // if (sendInProgress) {
    //   return;
    // }
    // setSendInProgress(true);
    // setTimeout(() => {
    //   setSendInProgress(false);
    //   setIsModalOpen(true);
    //   nameRef.current.value = "";
    //   emailRef.current.value = "";
    //   messageRef.current.value = "";
    //   phoneRef.current.value = "";
    // }, 1000);
  };
  return (
    <section
      id="contact"
      className="section white-bg page-section"
      data-aos="fade-up"
      data-aos-duration="600"
    >
      <div className="container-fluid">
        <SectionTitle title="Холбоо барих" subtitle="Холбоо барих" />
        <div className="row">
          {/* <div className="col-md-6">
            <div className="my-contact clearfix" style={{ marginTop: 0 }}>
              <div className="contact-icon contact-icon-wrapper">
                <span className="ti-mobile" />
              </div>
              <div className="contact-info">
                <h4>Ажлын утас</h4>
                <p>777777</p>
              </div>
            </div>
            <div className="my-contact clearfix">
              <div className="contact-icon contact-icon-wrapper">
                <span className="ti-email" />
              </div>
              <div className="contact-info">
                <h4>Захидал</h4>
                <p>77777</p>
              </div>
            </div>
            <div className="my-contact clearfix">
              <div className="contact-icon contact-icon-wrapper">
                <span className="ti-pencil-alt" />
              </div>
              <div className="contact-info">
                <h4>Бичиг хэрэг</h4>
                <p>777777</p>
              </div>
            </div>
          </div> */}
          <div className="col-md-6">
            <div className="contact-form-wrapper" style={{ paddingBottom: 0 }}>
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Таны нэр</label>
                  <input
                    ref={nameRef}
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder=""
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Утас</label>
                  <input
                    ref={phoneRef}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Имэйл хаяг</label>
                  <input
                    ref={emailRef}
                    className="form-control"
                    type="email"
                    placeholder=""
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Захидал бичих</label>
                  <textarea
                    ref={messageRef}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div
                  onClick={handleSubmit}
                  type="submit"
                  className="btn btn-primary"
                >
                  Илгээх
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
