import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import MarkDownIt from "markdown-it";
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import FooterSection from "../home_sections/FooterSection";
import PostsContext from "../context/PostsContext";
import ApiContext from "../context/ApiContext";
import { post } from "jquery";
import MenuResponsive from "../components/MenuResponsive";
import ClipLoader from "react-spinners/ClipLoader";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import RelatedNews from "../components/RelatedNews";
import { formatDate } from "../utils";
import Footer from "../components/Footer";

/*
  PostsContext дотор байхгүй бол server -с fetch хийнэ

  Search from PostsContext if not found, send http to api
*/

const md = new MarkDownIt();
const imgUrlRegex = new RegExp(/\/uploads/g);
export default function BlogSinglePage() {
  const { id } = useParams();
  const { posts, fetchPosts } = useContext(PostsContext);
  const history = useHistory();
  const [post, setPost] = useState(() => {
    if (posts) {
      return posts.filter((p) => p.id == id)[0];
    }
  });

  const imageUrl = useMemo(() => {
    if (post?.photo && post?.photo.length > 0) {
      return `${process.env.REACT_APP_API_URL}${post?.photo[0].url}`;
    }
    if (!post?.facebook_medias) {
      return "";
    }
    return post?.facebook_medias[0]?.url;
  }, [post?.photo[0], post?.facebook_medias]);

  const [loading, setLoading] = useState(false);
  const api = useContext(ApiContext);
  // fetch хийгдсэн post бол postCache нь null биш
  const fetchPost = async () => {
    try {
      const response = await api.get(`/posts/${id}`);
      setPost(response.data);
      setLoading(false);
    } catch (e) {
      history.push("/404");
    }
  };
  // social share link
  const url = useMemo(() => {
    return `/news/${id}`;
  }, [id]);
  const fullUrl = useMemo(() => {
    return `${process.env.REACT_APP_HOST_NAME}${url}`;
  }, [url]);

  // мэдээний dateStr
  const dateStr = useMemo(() => {
    return formatDate(post?.post_date);
  }, [post?.post_date]);

  // мэдээний body -г html болгох
  const newsBodyHtml = useMemo(() => {
    if (!post) {
      return "";
    }
    const body = post.body.replace(
      imgUrlRegex,
      `${process.env.REACT_APP_API_URL}/uploads`
    );
    return md.render(body);
  }, [post?.body]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // facebook images
  const facebookImages = useMemo(() => {
    if (!post?.facebook_medias || post.facebook_medias.length == 0) {
      return <></>;
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {post.facebook_medias.map((media, index) =>
          index == 0 ? (
            <></>
          ) : (
            <img
              className="img-responsive"
              style={{ marginBottom: "3rem" }}
              src={media.url}
            />
          )
        )}
      </div>
    );
  }, [post]);

  // description showed when shared to fb, twitter e.t.c
  const socialDescription = useMemo(() => {
    if (!post?.body) {
      return "";
    }
    return post.body.substr(0, 50);
  }, [post?.body]);
  // fetch post or use cache
  useEffect(() => {
    // cache дотор байгаа бол шууд харуулах
    let cache;
    if (posts) {
      cache = posts.filter((p) => p.id == id)[0];
    }
    if (cache) {
      // cache -ээс олдсон
      setPost(cache);
    } else {
      // cache дотор байхгүй
      // server -с авах
      fetchPost();
    }
  }, [id]);
  if (loading || !post) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loading />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        {/* social share title, images*/}

        {/* Facebook share */}
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={socialDescription} />
        <meta property="og:url" content={fullUrl} />
        <meta property="og:site_name" content={"batshugar.com"} />
        <meta property="og:updated_time" content={dateStr} />
        <meta property="og:published_time" content={dateStr} />
        <meta property="og:image" content={imageUrl} />
        {/* Facebook share end */}

        {/* Twitter share */}
        <meta property="twitter:title" content={post.title} />
        <meta property="twitter:description" content={socialDescription} />
        <meta property="twitter:url" content={fullUrl} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:site" content={"batshugar.com"} />
        <meta property="twitter:image" content={imageUrl} />
        {/* Twitter share end */}
      </Helmet>
      <div>
        <Navbar>
          <li>
            <Link to="/" className="page-scroll" onClick={() => fetchPosts()}>
              Эхлэл
            </Link>
          </li>
          <li>
            <Link to="/news" className="page-scroll">
              Мэдээ
            </Link>
          </li>
          <li>
            <a href="#about" className="page-scroll">
              Миний тухай
            </a>
          </li>
          <li>
            <a href="#resume" className="page-scroll">
              Товч намтар
            </a>
          </li>
          <li>
            <a href="#gallery" className="page-scroll">
              Зургийн цомог
            </a>
          </li>
        </Navbar>
        <section className="content-scroller">
          {/* <div className="page-header bg gradient-nt-01 bg-news">
            <div className="top-bar clearfix">
              <div className="back-home pull-left">
                <Link to="/news">
                  <span className="ti-angle-left" /> Буцах
                </Link>
              </div>
              <ul className="breadcrumb pull-right breadcrumb-custom">
                <li>
                  <Link to="/">
                    <span className="ti-home" /> Эхлэл
                  </Link>
                </li>
                <li>
                  <Link to="/news">Мэдээнүүд</Link>
                </li>
                <li className="active">Мэдээ</li>
              </ul>
            </div>

            <div className="page-header-title">
              <h1>{post.title}</h1>
              <div className="blog-meta">
                <p>
                  <span className="ti-user" />{" "}
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    Admin{" "}
                  </a>
                </p>
                <p>
                  <span className="ti-folder" />{" "}
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    Мэдээ
                  </a>
                </p>
              </div>
            </div>
          </div> */}
          <section className="blog blog-single white-bg page-section-pt">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-9 col-md-8">
                  <div className="item">
                    <h2>{post.title}</h2>
                    <div className="blog-meta">
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "2rem" }}></div>
                        <p>
                          <span className="ti-folder" />{" "}
                          <a href="#" onClick={(e) => e.preventDefault()}>
                            Мэдээ
                          </a>
                        </p>
                        <div style={{ marginRight: "2rem" }}></div>
                        <p>
                          <span className="ti-calendar" />{" "}
                          <a href="#" onClick={(e) => e.preventDefault()}>
                            {dateStr}
                          </a>
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          className="img-responsive"
                          alt="News Image"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    className="content news-single"
                    dangerouslySetInnerHTML={{ __html: newsBodyHtml }}
                  ></div>
                  {facebookImages}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4 className="news-single-date">{dateStr}</h4>
                    <div className="news-share-container">
                      <ul>
                        <li>
                          <FacebookShareButton url={fullUrl}>
                            <a href="#" onClick={() => false}>
                              <i className="fa fa-facebook" />
                            </a>
                          </FacebookShareButton>
                        </li>
                        <li>
                          <TwitterShareButton url={fullUrl}>
                            <a href="#" onClick={() => false}>
                              <i className="fa fa-twitter" />
                            </a>
                          </TwitterShareButton>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="slidebar">
                    <RelatedNews id={post.id} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <a
          href="#"
          className="back-to-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short" />
        </a>
      </div>
      <Footer />
    </>
  );
}
